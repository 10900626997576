import moment from 'moment';
import * as yup from 'yup';
import {
    DateFormats,
    EventActionType,
    ValidationEnums,
} from '../../../shared/constants/application.constant';
import { ConstNumber } from '../../../shared/constants/number.constant';
import { SubmitButton } from '../../../shared/elements/Buttons';
import {
    compareStartAndCurrentTime,
    formatChangeDateTime,
    formatDateAndTime,
    getAdjustedStartDate,
} from '../../../shared/utils/helper';
import {
    Category,
    FormParams,
    Option,
} from '../../../shared/utils/types.elements';
import { DateTimeParam } from '../../specials/utils/types.specials';
import {
    Event,
    EventForm,
    EventFormRequest,
    EventFormWarehouse,
    EventV2,
} from '../constants/types.events';
import { UpdateFieldsProp } from './slice.types';

export const getEventFormIntialValues = (): EventForm => {
    return {
        id: undefined,
        name: '',
        address: ConstNumber.VALUE_0,
        start_date: getAdjustedStartDate().toDate(),
        end_date: getAdjustedStartDate().toDate(),
        start_time: moment().add(ConstNumber.VALUE_15, 'minutes').toDate(),
        end_time: moment().add(ConstNumber.VALUE_30, 'minutes').toDate(),
        description: null,
        categories_list: [],
        document: null,
        price_range: null,
        ticket_url: null,
    };
};

export const eventFormSchema = yup.object().shape({
    id: yup.number().optional(),
    name: yup
        .string()
        .max(ConstNumber.VALUE_100, ValidationEnums.MAX_100)
        .required(ValidationEnums.REQUIRED),
    address: yup
        .number()
        .min(ConstNumber.VALUE_1, ValidationEnums.REQUIRED)
        .required(ValidationEnums.REQUIRED),
    start_time: yup
        .date()
        .test(
            'is-min',
            ValidationEnums.START_TIME_ERROR,
            compareStartAndCurrentTime
        )
        .typeError(ValidationEnums.REQUIRED)
        .required(ValidationEnums.REQUIRED),
    start_date: yup
        .date()
        .typeError(ValidationEnums.REQUIRED)
        .required(ValidationEnums.REQUIRED),
    end_time: yup
        .date()
        .test(
            'is-same',
            ValidationEnums.SAME_START_END_ERROR,
            function (value) {
                const { start_time } = this.parent;
                const endDateIntoString = JSON.stringify(value);
                const startDateIntoString = JSON.stringify(start_time);
                return endDateIntoString !== startDateIntoString;
            }
        )
        .required(ValidationEnums.REQUIRED),
    end_date: yup
        .date()
        .min(yup.ref('start_date'), ValidationEnums.END_DATE_ERROR)
        .typeError(ValidationEnums.REQUIRED)
        .required(ValidationEnums.REQUIRED),
    categories_list: yup
        .array()
        .of(yup.number().required())
        .min(ConstNumber.VALUE_1, ValidationEnums.REQUIRED)
        .required(ValidationEnums.REQUIRED),
    description: yup
        .string()
        .max(ConstNumber.VALUE_1000, ValidationEnums.MAX_1000)
        .nullable(),
    price_range: yup.number().nullable(),
    ticket_url: yup.string().url(ValidationEnums.VALID_WEBSITE).nullable(),
});

export const eventUploadSchema = yup.object().shape({
    document: yup
        .mixed()
        .test('fileType', ValidationEnums.ONLY_CSV, (value) => {
            const fileType = (value as any)[0]?.type;
            if (!fileType) {
                return true;
            }
            return (
                fileType === 'text/csv' ||
                fileType === 'application/vnd.ms-excel'
            );
        })
        .required(ValidationEnums.REQUIRED),
});

export const getUniqueLevel2Data = (
    eventOptionSelected: Option[],
    eventCategories: Category[]
): string[] => {
    // Extracting the selected option values
    const selectedOptionValues = eventOptionSelected.map(
        (option) => option.value
    );
    // Filtering the category array based on selected option values
    const filteredEventCategories = eventCategories.filter((category) =>
        selectedOptionValues.includes(category.id)
    );
    // Creating a new array with unique level_2 data
    return Array.from(
        new Set(
            filteredEventCategories.reduce<string[]>((acc, category) => {
                category.category_relation.forEach((relation) => {
                    acc.push(relation.level_2.name);
                });
                return acc;
            }, [])
        )
    );
};

export const getCategoryOptionsData = (
    eventCategories: Category[]
): Option[] => {
    return eventCategories.map((category) => {
        return { value: category.id, label: category.name };
    });
};

export const getMapDateAndTime = (
    date: Date | null,
    time: Date,
    typeParam: DateTimeParam
): Date => {
    const newDate = moment(date).format(
        typeParam === 'date' ? DateFormats.YYYY_MM_DD : DateFormats.HH_MM
    );
    const newTime = moment(time).format(
        typeParam === 'date' ? DateFormats.HH_MM : DateFormats.YYYY_MM_DD
    );
    return moment(
        `${typeParam === 'date' ? newDate : newTime} ${
            typeParam === 'date' ? newTime : newDate
        }`,
        `${DateFormats.YYYY_MM_DD} ${DateFormats.HH_MM}`
    ).toDate();
};

export const getCategoryData = (
    eventDataById: EventV2 | undefined,
    eventCategoryOptions: Option[]
) => {
    return eventDataById?.categories_list.map((item) => {
        const category = eventCategoryOptions.find(
            (val) => val.value === item.category.id
        );
        return {
            label: category ? category.label : '',
            value: item.category.id,
        };
    });
};

export const getUpdatedValues = (
    previous: EventFormRequest,
    latest: EventFormRequest
) => {
    type UpdatedFields = { [key: string]: UpdateFieldsProp };
    const updatedValues: UpdatedFields = {};
    for (const key in latest) {
        const keyString = key as keyof EventFormRequest;
        if (
            !(keyString in previous) ||
            latest[keyString] !== previous[keyString]
        ) {
            updatedValues[keyString] = latest[keyString] as any;
        }
    }

    return updatedValues;
};

export const getDataRequest = (
    data: EventForm | EventFormWarehouse
): EventFormRequest => {
    return {
        name: data.name,
        address: data.address,
        description: data.description ?? '',
        categories_list: JSON.stringify(data.categories_list),
        start_datetime: formatDateAndTime(data.start_date, data.start_time),
        end_datetime: formatDateAndTime(data.end_date, data.end_time),
        document: data?.document || null,
        price_range: data.price_range as any,
        ticket_url: (data.ticket_url as any) || null,
        s3MediaUrl: data?.s3MediaUrl || '',
        display_start_datetime: formatChangeDateTime(
            data.start_date,
            data.start_time
        ),
        display_end_datetime: formatChangeDateTime(
            data.end_date,
            data.end_time
        ),
    };
};

export const getFooterButton = (
    params: FormParams,
    isValid: boolean,
    isLoading: boolean,
    isUpdating: boolean,
    isSubmitting: boolean
) => {
    return (
        <div className="button-footer">
            <SubmitButton
                label={
                    params?.id && params?.type === EventActionType.EDIT
                        ? 'Update & Next'
                        : 'Save & Next'
                }
                isDisable={!isValid || isLoading || isUpdating || isSubmitting}
                isLoading={isLoading || isUpdating || isSubmitting}
                customClass="medium"
                loadingLabel={
                    params?.id && params?.type === EventActionType.EDIT
                        ? 'Updating...'
                        : 'Saving...'
                }
            />
        </div>
    );
};
