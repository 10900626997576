export enum Heading {
    WELCOME_BACK = 'Welcome, ',
    EVENTS = 'Events',
    EVENTS_SUBTEXT = 'Here are the events for your business.',
    SPECIALS = 'Specials',
    SPECIALS_SUBTEXT = 'Effortlessly add and manage your local specials and happy hours',
    CREATE_SPECIAL_SUBTEXT = 'Set up and manage your enticing offers, captivating customers and maximizing your business potential',
    ADVERTISEMENT = 'Advertisement',
    ADVERTISEMENT_SUBTEXT = 'Publish your ad to attract a wider audience and increase event visibility',
    BUSINESS = 'Business',
    BUSINESS_SUBTEXT = 'Here you can set up your business venue demographic, uniqueness & information',
    SUBSCRIPTION = 'Active Subscription Plan',
    LOGOUT = 'Sign Out',
    CANCEL = 'Cancel',
    CONfIRM_LOGOUT = 'Are you sure you want to sign out of the app?',
    NO = 'No',
    YES = 'Yes',
    EXIT = 'Exit',
    CONFIRM_EXIT = 'Are you sure you want to exit?',
    ADMIN = 'Admin',
    DELETE = 'Delete',
    DELETE_EVENT = 'Delete Event',
    DELETE_EVENT_SUBTEXT = 'Are you sure you want to delete',
    DELETE_LOCAL_SPECIALS = 'Delete Local Special',
    DELETE_HAPPY_HOURS = 'Delete Happy Hour',
    DELETE_CATEGORY = 'Delete Category',
    DELETE_CATEGORY_SUBTEXT = `Are you sure you want to proceed?<br/> Please note that this category's data will be deleted or unlinked.`,
    DELETE_EVENT_SUBTEXT_ADMIN = 'Are you sure you want to delete this event?',
    DEACTIVATE_ACCOUNT = 'Deactivate Account?',
    DEACTIVATE_ACCOUNT_SUBTEXT = 'Are you sure you want to Deactivate the account?',
    ACTIVATE_ACCOUNT = 'Activate Account?',
    ACTIVATE_ACCOUNT_SUBTEXT = 'Are you sure you want to Activate the account?',
    DEACTIVATE_SUBADMIN_SUBTEXT = 'Are you sure you want to proceed?<br/> Please note that this sub-admin will no longer be able to log in.',
    ACTIVATE_SUBADMIN_SUBTEXT = 'Are you sure you want to proceed?<br/> Once activated, this sub-admin will have login access.',
    DEACTIVATE_SUBADMIN = 'Deactivate Sub-Admin',
    ACTIVATE_SUBADMIN = 'Activate Sub-Admin',
    DELETE_SPECIALS = 'Delete Local Special',
    DELETE_SPECIAL_SUBTEXT_ADMIN = 'Are you sure you want to delete this Special?',
    CANCEL_SUBSCRIPTION = 'Cancel Subscription',
    CANCEL_SUBSCRIPTION_SUBTEXT = "When you choose to cancel auto-renewal, your upcoming subscription payment will be halted, and on the next auto-renewal date, you will be logged out. To continue using the service, you'll need to sign in and subscribe to a new plan.",
    CANCEL_SUBSCRIPTION_SUBTEXT_V2 = "When you choose to cancel auto-renewal, you will be logged out. To continue using the service, you'll need to sign in and subscribe to a new plan.",
    OK_AGREE = 'OK, I Agree',
    DELETE_BUSINESS_SUBTEXT_ADMIN = 'Are you sure you want to delete this Business?',
    DELETE_LOCATION_SUBTEXT_ADMIN = 'Are you sure you want to delete this business location?',
    DELETE_BUSINESS = 'Delete Business',
    DELETE_BUSINESS_LOCATION = 'Delete Business Location',
    DELETE_PROMOTION_CODE = 'Delete Promotion Code',
    DELETE_PROMOTION_CODE_SUBTEXT = 'Are you sure you want to delete this promotion code?',
    DEACTIVATE_PAYMENT_PLAN = 'Deactivate Payment Plan',
    ACTIVATE_PAYMENT_PLAN = 'Activate Payment Plan',
    DEACTIVATE_PAYMENT_PLAN_SUBTEXT = 'Are you sure you want to deactivate the payment plan?',
    ACTIVATE_PAYMENT_PLAN_SUBTEXT = 'Are you sure you want to activate the payment plan?',
    ALL_LINKED_CATEGORY_UNLINKED = '<br/> All linked categories will get un-linked.',
}

export enum CommonEnum {
    HOTLIST = 'Hotlist',
    FAVORITES = 'Favorites',
    FOLLOWINGS = 'Followings',
    ATTENDEES = 'Attendees',
    PLEASE_UPDATE_NAME = '(Please update name)',
}

export enum ToastEnum {
    SPECIALS_CREATED = 'Specials Created',
    SPECIALS_UPDATED = 'Specials Updated',
    VENUE_DETAIL_SAVED = 'Venue Details Saved',
    SOMETHING_WENT_WRONG = 'Oops! Something went wrong',
    EVENT_CREATED = 'Event Created',
    PASSWORD_RESET = 'Password has been reset successfully.',
    VERIFIED = 'OTP Verified successfully.',
    OTP_VERIFIED_DECS = 'Code verified successfully.',
    OTP_SENT = 'Code Sent',
    OTP_SEND_DECS = 'Code has been sent on registered Email Id.',
    RESEND = 'Code will be resend after 60 seconds.',
    RESEND_DESC = 'Code will be resend after 60 seconds.',
    CATEGORY_CREATED = 'Category Created',
    EVENT_DETAIL_SAVED = 'Event Details Saved',
    EVENT_UPDATED = 'Event Updated',
    EVENT_DELETED = 'Event Deleted',
    SPECIALS_DELETED = 'Specials Deleted',
    GROUP_CREATED = 'Group Created',
    SUBCATEGORY_CREATED = 'Subcategory Created',
    CATEGORY_DELETED = 'Category Deleted',
    STATUS_CHANGED = 'Status Changed',
    ACCOUNT_DEACTIVATED = 'Account deactivated.',
    ACCOUNT_DEACTIVATED_MSG = 'Your account has been deactivated by Admin.',
    SUBADMIN_CREATED = 'Sub-Admin Created',
    AD_CREATED = 'Ad Created',
    SPECIAL_DELETED = 'Special Deleted',
    PRESIGNED_URL_ERROR = 'Pre-signed url could not be fetched',
    AD_UPDATED = 'Ad Updated',
    PASSWORD_CHANGE = 'Password Changed',
    PASSWORD_CHANGE_MSG = 'Password has been changed successfully.',
    SUBCATEGORY_UPDATED = 'Subcategory Updated',
    ALREADY_ADDED = 'Already added',
    DETAIL_ALREADY_ADDED = 'Plan detail already added.',
    PAYMENT_PLAN_CREATED = 'Payment Plan Created',
    ERROR_AUTO_SIGNIN = 'Failed to sign in !',
    PROMOTION_CREATED = 'Promotion Code Created',
    PAYMENT_DONE = 'Payment Completed',
    BUSINESS_DELETED = 'Business Deleted',
    SUBSCRIPTION_CANCELLED = 'Subscription Cancelled',
    BUSINESS_LOCATION_DELETED = 'Business Location Deleted',
    PROMOTION_CODE_DELETED = 'Promotion Code Deleted',
    PAYMENT_PLAN_UPDATED = 'Payment Plan Updated',
    EVENTS_CREATED = 'Events Created',
    APPROVED = 'Approved',
    REJECTED = 'Rejected',
    NOTIFICATION_SENT = 'Notification Sent',
    EMAIL_NOT_EXIST = 'The email does not exist',
    INVLD_PASSWORD = 'Invalid Password',
    PAYMENT_HOLD_CONTACT_ADMIN = 'Click on three pin to refresh the payment status. Please contact Admin for more information.',
    PAYMENT_HOLD_APPROVE_PAYMENT = 'Kindly update the status to initiate the display of this advertisement.',
    INCORRECT_EMAIL_PASSWORD = 'Incorrect email or password.',
    INCORRECT_CURRENT_PASSWORD = 'Incorrect current password.',
    SUBSCRIPTION_CHANGED = 'Newsletter Subscription Updated',
    PASSWORD_CHANGE_LIMIT = 'Password Change Limit Exceeded. Please try again later.',
    PAYMENT_UPDATES = 'Payment Updates',
}

export enum NoDataEnums {
    NO_DATA_FOUND = 'No results were found!',
    NO_RESULT_FOUND = 'No result found',
    NO_PATH_FOUND = 'The Path you are looking for is not Found',
    INTERNAL_ERROR = 'Oops! Something went wrong.',
    UNDER_DEVELOPEMNT = 'The page you are looking for is under construction',
    BROWSER_DOES_NOT_SUPPORT = 'The browser does not support videos.',
    NO_LOCAL_SPECIAL_FOUND = 'No Local Specials Found',
    NO_HAPPY_HOUR_FOUND = 'No Happy Hours Found',
    NO_VENNUE_DETAILS_FOUND = 'No Venue Details Found',
    NO_SUBCATEGORY_FOUND = 'No Subcategories Found',
    NO_EVENTS_FOUND = 'No Events Found',
    NO_SPECIALS_FOUND = 'No Special Found',
    NO_SUBADMIN_FOUND = 'No Sub-Admin Found',
    NO_ADS_FOUND = 'No Ads Found',
    NO_ADS_FOUND_SUBHEADING = 'Click on "Add New Ad" to create an ad.',
    NO_PAYMENT_PLAN_FOND = 'No Payment Plans Found',
    NO_SUBCATEGORY_SUBHEADING = `Click on "Add" to add subcategories.`,
    NO_SBSCRIPTION_FOUND = 'No Subscription Found',
    NO_BUSINESS_FOUND = 'No Business Found',
    NO_PROMOTION_CODE_FOUND = 'No Promotion Code Found',
    NO_ACTIVE_PLAN = 'No Active Plan Found',
    NO_ADDRESS = 'No Address Found',
    NO_ACTIVE_PLAN_SUBHEADING = "Oops! You don't have any active plan.",
    NO_NOTIFICATION_FOUND = 'No Notification Found',
    NO_CLAIM_EVENTS_FOUND = 'No Event Claim Requests Found',
}

export enum ButtonLabel {
    LOADING = 'Loading...',
    WAIT = 'Please wait',
    VERIFY = 'Verify',
    VERIFING = 'Verifying...',
    UPDATE = 'Update',
    SAVE = 'Save',
    SAVING = 'Saving...',
    UPDATING = 'Updating...',
    DELETING = 'Deleting...',
    DEACTIVATE = 'Deactivate',
    DELETE = 'Delete',
    DEACTIVATING = 'Deactivating...',
    ACTIVATE = 'Activate',
    ACTIVATING = 'Activating...',
    ADDING = 'Adding...',
    SUBMITTING = 'Submitting...',
    SUBMIT = 'Submit',
    SIGN_IN = 'Sign In',
    SIGNIN_IN = 'Signing In...',
    SUBSCRIBE = 'Subscribe Newsletter',
    CREATE = 'Create',
    CREATING = 'Creating...',
    CANCELING = 'Canceling...',
    SUBSCRIPTION = 'Subscription',
    BACK_TO_SIGNIN = 'Back to Sign In',
    SAVE_NEXT = 'Save & Next',
    UPDATE_NEXT = 'Update & Next',
    UPLOAD = 'Upload',
    UPLOADING = 'Uploading...',
    ACCEPT = 'Accept',
    ACCEPTING = 'Accepting',
    SEND = 'Send',
    SENDING = 'Sending...',
    APPROVE = 'Approve',
    PUBLISH = 'Publish',
    PUBLISHING = 'Publishing...',
}

export enum SidebarLinks {
    DASHBOARD = 'Dashboard',
    BUSINESS_INFO = 'Business Info',
    VENUE_DEMOGRAPHICS = 'Venue Demographics',
    VENUE_LOCATION = 'Venue Locations',
    VENUE_DETAILS = 'Primary Venue Details',
    EVENTS = 'Events',
    SPECIALS = 'Specials',
    ADVERTISING = 'Advertisement',
    BUSINESSES = 'Businesses',
    CATEGORIES = 'Categories',
    EVENT_CLAIMS = 'Event Claims',
    API_EVENTS = 'All Events',
    DATA_WAREHOUSE = 'Data Warehouse',
    API_EVENTS_SUBTEXT = '(External Sources)',
    PROMOTION_CODE = 'Promotion Code',
    NOTIFICATION = 'Notification',
    ADMIN_USERS = 'Admin Users',
    SUBSCRIPTIONS = 'Subscriptions',
    PAYMENT_PLANS = 'Payment Plans',
}

export enum DeactivateInfo {
    BUSINESS = 'Does this business have inappropriate or incorrect information?',
    EVENT = 'Does this event have inappropriate or incorrect information?',
    SPECIAL = 'Does this local special have inappropriate or incorrect information?',
    HAPPY_HOUR = 'Does this happy hour have inappropriate or incorrect information?',
}

export enum PageTitleEnum {
    VENUE_LOCATION = 'Venue Locations',
}

export enum HelpText {
    DYNAMIC_ANALYTICS = ' These figures will fluctuate based on your ongoing or concluded events and ',
    DYNAMIC_ANALYTICS_SECOND = 'the number of consumers choosing to follow or unfollow your account or events.',
    JUNK_EMAIL = "Make sure to give your spam or junk folder a peek if the code doesn't pop up in your inbox!",
    NOTE = 'Note',
}
