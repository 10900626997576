import { ConstNumber } from '../../../shared/constants/number.constant';
import { FilterArg } from '../../../shared/types/type';
import { getTimeZone } from '../../../shared/utils/helper';
import { apiUrl } from '../constants/apiUrl.apiEvents';

export function getUrl(isWarehouse: boolean) {
    return isWarehouse ? apiUrl.WAREHOUSE_EVENTS : apiUrl.EXTERNAL_EVENTS;
}

export const getQueryParams = (_page: number, _filters: FilterArg) => {
    const { endDate, startDate, city, is_published } = _filters;
    let query = `?limit=12&offset=${
        (_page - ConstNumber.VALUE_1) * ConstNumber.VALUE_12
    }`;
    if (city) {
        query += `&search=${city}`;
    }
    if (startDate && endDate) {
        query += `&start_date=${startDate}&end_date=${endDate}`;
    }
    if (is_published !== undefined) {
        query += `&is_published=${is_published}`;
    }
    query += `&time_zone=${getTimeZone()}`;
    return query;
};
