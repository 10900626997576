import moment from 'moment';
import * as yup from 'yup';
import {
    DateFormats,
    ValidationEnums,
} from '../../../shared/constants/application.constant';
import { OPENHOURS } from '../../../shared/constants/data.constant';
import { ConstNumber } from '../../../shared/constants/number.constant';
import {
    Category,
    Option,
    TimeSlot,
} from '../../../shared/utils/types.elements';
import {
    BusinessLocation,
    BusinessUserDetais,
    BusinessUserRequest,
    NewLocation,
    OpeningHours,
    VenueDemographics,
} from './types.businessInfo';
import { formatPhoneWithCountryCode } from '../../../shared/utils/helper';

export function getDemographicsFormIntialValues() {
    return {
        business_name: '',
        address: '',
        contact_number: '',
        city: '',
        zip_code: '',
        business_description: null,
        price_range: null,
        website: null,
        categories_list: [],
        start_time: undefined,
        end_time: undefined,
        opening_hours_list: null,
    };
}

export const demographicsFormSchema = yup.object().shape({
    business_name: yup.string().required(ValidationEnums.REQUIRED),
    address: yup.string().required(ValidationEnums.REQUIRED),
    contact_number: yup.string().required(ValidationEnums.REQUIRED),
    city: yup.string().required(ValidationEnums.REQUIRED),
    zip_code: yup.string().required(ValidationEnums.REQUIRED),
    website: yup.string().url(ValidationEnums.VALID_WEBSITE).nullable(),
    categories_list: yup
        .array()
        .of(yup.number().required())
        .min(ConstNumber.VALUE_1, ValidationEnums.REQUIRED)
        .required(ValidationEnums.REQUIRED),
    start_time: yup.date().notRequired(),
    end_time: yup.date().notRequired(),
    opening_hours_list: yup.string().nullable(),
    business_description: yup.string().nullable(),
    price_range: yup.number().nullable(),
});

const arraysEqual = (arr1: number[], arr2: number[]): boolean => {
    if (arr1.length !== arr2.length) {
        return false;
    }

    for (let i = ConstNumber.VALUE_0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) {
            return false;
        }
    }

    return true;
};

export const getUpdatedValues = (
    previous: BusinessUserRequest,
    latest: BusinessUserRequest
) => {
    type UpdatedFields = { [key: string]: string | number[] | File };
    const updatedValues: UpdatedFields = {};
    for (const key in latest) {
        if (key === 'category') {
            if (
                !(key in previous) ||
                !arraysEqual(latest[key] as number[], previous[key] as number[])
            ) {
                updatedValues[key] = latest[key] as any;
            }
        } else if (!(key in previous) || latest[key] !== previous[key]) {
            updatedValues[key] = latest[key] as any;
        }
    }

    return updatedValues;
};

export const getSlotData = (openingHours: OpeningHours[]): TimeSlot[] => {
    return openingHours.map((days) => {
        return {
            value: days.day,
            label:
                OPENHOURS.find((hour) => hour.value === days.day)?.label ?? '',
            slug: OPENHOURS.find((hour) => hour.value === days.day)?.slug ?? '',
            start_time: moment(
                `${moment().format(DateFormats.YYYY_MM_DD)} ${days.start_time}`
            ).format(DateFormats.TIME),
            end_time: moment(
                `${moment().format(DateFormats.YYYY_MM_DD)} ${days.end_time}`
            ).format(DateFormats.TIME),
            start_date: moment(
                `${moment().format(DateFormats.YYYY_MM_DD)} ${days.start_time}`
            ).toString(),
            end_date: moment(
                `${moment().format(DateFormats.YYYY_MM_DD)} ${days.end_time}`
            ).toString(),
        };
    });
};

export const getPreData = (
    businessDetails: BusinessUserDetais
): BusinessUserRequest => {
    return {
        business_description: businessDetails.business.business_description,
        price_range: businessDetails.business.price_range,
        website: businessDetails.business.website,
        categories_list: businessDetails.business.categories_list?.map(
            (val) => val.category.id
        ),
        opening_hours_list: JSON.stringify(
            businessDetails.business.opening_hours
        ),
        profile_video: businessDetails.business.profile_video,
    };
};

export const getCategoryData = (
    businessDetails: BusinessUserDetais | undefined,
    categoryOptions: Option[]
) => {
    return businessDetails?.business.categories_list.map((item) => {
        const category = categoryOptions.find(
            (val) => val.value === item.category.id
        );
        return {
            label: category ? category.label : '',
            value: item.category.id,
        };
    });
};

export const getOpenHours = (timeSlot: TimeSlot[]) => {
    return timeSlot.map((days) => {
        return {
            day: days.value,
            start_time: moment(days.start_date).format(DateFormats.TIME_SECOND),
            end_time: moment(days.end_date).format(DateFormats.TIME_SECOND),
        };
    });
};

export const getDataRequest = (val: VenueDemographics): BusinessUserRequest => {
    return {
        categories_list: JSON.stringify(val.categories_list),
        business_description: val.business_description,
        price_range: val.price_range,
        website: val.website,
        opening_hours_list: val.opening_hours_list,
        profile_video: val.profile_video as any,
    };
};

export const getUpdatedTimeSlot = (
    daysSelected: Option[],
    getValues: any,
    startKey = 'start_time',
    endKey = 'end_time'
): TimeSlot[] => {
    return daysSelected.map((days) => {
        return {
            value: days.value,
            label: days.label,
            slug: days.slug,
            start_time: moment(getValues(startKey)).format(DateFormats.TIME),
            end_time: moment(getValues(endKey)).format(DateFormats.TIME),
            start_date: getValues(startKey) ?? '',
            end_date: getValues(endKey) ?? '',
        };
    });
};

export const getUniqueLevel2Data = (
    optionSelected: Option[],
    categories: Category[]
): string[] => {
    // Extracting the selected option values
    const selectedOptionValues = optionSelected.map((option) => option.value);
    // Filtering the category array based on selected option values
    const filteredCategories = categories.filter((category) =>
        selectedOptionValues.includes(category.id)
    );
    // Creating a new array with unique level_2 data
    return Array.from(
        new Set(
            filteredCategories.reduce<string[]>((acc, category) => {
                category.category_relation.forEach((relation) => {
                    acc.push(relation.level_2.name);
                });
                return acc;
            }, [])
        )
    );
};

export const getCategoryOptionsData = (categories: Category[]): Option[] => {
    return categories.map((category) => {
        return { value: category.id, label: category.name };
    });
};

export const setInitialValuesData = (
    businessDetails: BusinessUserDetais,
    setValue: any
) => {
    const business = businessDetails.business;
    const openingHours = JSON.stringify(business.opening_hours);
    setValue('business_name', business.business_name);
    setValue('address', business.address);
    setValue(
        'contact_number',
        formatPhoneWithCountryCode(business.business_phone_number)
    );
    setValue('city', business.city);
    setValue('zip_code', business.zip_code);
    setValue('business_description' as any, business.business_description);
    setValue('price_range', business.price_range);
    setValue('website', business.website);
    setValue('profile_video' as any, business.profile_video);
    setValue('opening_hours_list', openingHours);
};

export const getNewLocationInitialValues = () => {
    return {
        location_name: '',
        address: '',
        city: '',
        state: undefined,
        zip_code: '',
        place_id: undefined,
        latitude: undefined,
        longitude: undefined,
    };
};

export const addNewLocationSchema = yup.object().shape({
    location_name: yup
        .string()
        .required(ValidationEnums.REQUIRED)
        .trim()
        .max(ConstNumber.VALUE_255, ValidationEnums.MAX_255),
    address: yup.string().required(ValidationEnums.CHOOSE_ADDRESS),
    city: yup.string().required(ValidationEnums.REQUIRED),
    state: yup.string().optional(),
    zip_code: yup.string().required(ValidationEnums.REQUIRED),
    place_id: yup.string().optional(),
    latitude: yup.string().optional(),
    longitude: yup.string().optional(),
});
