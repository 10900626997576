import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
    apiMessage,
    getLabel,
    getSpecialFormIntialValues,
    getSpecialName,
    handleDate,
    navigateToListingScreen,
    setIntialObject,
    setIntialValueData,
    specialFormSchema,
} from '../utils/util';
import {
    CreateSpecial,
    CreateSpecialParams,
    DateType,
    LocalSpecials,
} from '../utils/types.specials';
import { yupResolver } from '@hookform/resolvers/yup';
import {
    useCreateSpecialMutation,
    useGetSpecialByIdQuery,
    useUpdateSpecialMutation,
} from '../slices/slice.specials';
import { useLocation, useNavigate } from 'react-router-dom';
import { EventActionType } from '../../../shared/constants/application.constant';
import { ErrorObj } from '../../../shared/types/type';
import {
    FormParams,
    Option,
    TimeSlot,
} from '../../../shared/utils/types.elements';
import {
    getOpenHours,
    getSlotData,
    getUpdatedTimeSlot,
} from '../../businessInfo/utils/businessUtils';
import { ConstNumber } from '../../../shared/constants/number.constant';
import { ternaryLogic } from '../../../shared/utils/orLogic';
import { uploadTos3 } from '../../../shared/utils/api.meta';
import {
    convertToOptions,
    generateBusinessLocationOptions,
    getS3MediaUrl,
} from '../../../shared/utils/helper';
import { useGetBusinessLocationsQuery } from '../../businessInfo/slices/slice.businessInfo';

let globalObject: CreateSpecial | undefined = undefined;

export function useCreateSpecial(params: FormParams) {
    const { id, type } = params;
    const navigate = useNavigate();
    const { activeTab } = useLocation().state as CreateSpecialParams;
    const [show, setShow] = useState(false);
    const [daysSelected, setDaysSelected] = useState<Option[]>([]);
    const [timeSlot, setTimeSlot] = useState<TimeSlot[]>([]);
    const { data: locations } = useGetBusinessLocationsQuery({});
    const [isDaySelected, setisDaySelected] = useState<boolean>(false);

    const businessOptions: Option[] = useMemo(
        () => generateBusinessLocationOptions(locations),
        [locations]
    );

    const [createSpecial, { isLoading, error: apiError, data }] =
        useCreateSpecialMutation();
    const [
        updateSpecial,
        { isLoading: isUpdating, error: updateError, data: updateData },
    ] = useUpdateSpecialMutation();
    /**
     * it will skip if user is in create mode
     */
    const { data: specialDataById, isLoading: isDataFethcing } =
        useGetSpecialByIdQuery(id, {
            skip: !Boolean(id),
        });

    const {
        handleSubmit,
        register,
        formState: { errors, isValid, isSubmitting },
        setValue,
        getValues,
        trigger,
        watch,
        control,
    } = useForm({
        defaultValues: getSpecialFormIntialValues({
            activeTab,
            address: ConstNumber.VALUE_0,
        }),
        resolver: yupResolver(specialFormSchema),
        mode: 'onChange',
    });

    const onSubmitForm = useCallback(
        async (val: CreateSpecial): Promise<void> => {
            let res,
                media = false; // media will contain whether the doc is uploaded or not
            if (val?.document && val?.s3MediaUrl) {
                media = await uploadTos3(val.s3MediaUrl, val.document);
            }
            if (id && type === EventActionType.EDIT) {
                // edit
                res = await updateSpecial({
                    data: {
                        ...val,
                        s3MediaUrl: getS3MediaUrl(media, val.s3MediaUrl),
                    },
                    initialValues: globalObject as CreateSpecial,
                });
            } else {
                //create or duplicate
                res = await createSpecial({
                    ...val,
                    duplicateId: id,
                    s3MediaUrl: getS3MediaUrl(media, val.s3MediaUrl),
                });
            }
            'data' in res && navigateToListingScreen(navigate, val.offer_type);
        },
        []
    );

    const setInitialValues = useCallback(async (_data?: LocalSpecials) => {
        if (_data !== undefined) {
            const obj = setIntialObject(_data);
            // this globalobject to keep track of the intial values fetched from the api
            globalObject = obj;
            setIntialValueData(_data, obj, type, setValue);
            const slotData: TimeSlot[] = getSlotData(_data.opening_hours);
            setTimeSlot(slotData);
        }
    }, []);

    const getSelectedValue = () => {
        const selectedLocation = locations?.find(
            (location) => location.id === getValues('address')
        );
        if (selectedLocation) {
            return convertToOptions([selectedLocation], 'id', 'address');
        } else {
            return [];
        }
    };

    const handleDaysChange = useCallback(
        (days: Option[]): void => {
            setDaysSelected(days);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [daysSelected]
    );

    const handleTimeChange = (
        date: Date | null,
        dateType: DateType | string
    ) => {
        const timeType = ternaryLogic(
            dateType === 'oh_start_time',
            'oh_start_time',
            'oh_end_time'
        );
        setValue(timeType, date);
        trigger(['oh_start_time', 'oh_end_time']);
    };

    const saveSlotHandler = () => {
        const updatedTimeSlot: TimeSlot[] = getUpdatedTimeSlot(
            daysSelected,
            getValues,
            'oh_start_time',
            'oh_end_time'
        );
        setTimeSlot([...timeSlot, ...updatedTimeSlot]);
        const openHoursData = getOpenHours([...timeSlot, ...updatedTimeSlot]);
        setValue('opening_hours_list', JSON.stringify(openHoursData));
        // reset dropdown open hours
        setValue('oh_start_time', undefined);
        setValue('oh_end_time', undefined);
        setDaysSelected([]);
        trigger(['oh_start_time', 'oh_end_time', 'opening_hours_list']);
    };

    const isSlotDisabled = (option: Option) => {
        return (
            Boolean(timeSlot.find((time) => time.value === option.value)) ||
            Boolean(timeSlot.length && option.value === '*')
        );
    };

    const removeTimeSlot = (_id: number) => {
        const slotIndex = timeSlot.findIndex((obj) => obj.value === _id);
        if (slotIndex > -ConstNumber.VALUE_1) {
            const newTimeSlot = [...timeSlot];
            newTimeSlot.splice(slotIndex, ConstNumber.VALUE_1);
            setTimeSlot(newTimeSlot);
            const openHoursData = getOpenHours(newTimeSlot);
            setValue('opening_hours_list', JSON.stringify(openHoursData));
            trigger(['opening_hours_list']);
        }
    };

    useEffect(() => {
        apiMessage(
            data?.message,
            apiError as ErrorObj,
            updateData?.message,
            updateError as ErrorObj
        );
    }, [data?.message, apiError, updateData?.message, updateError]);

    const handleDateChange = (
        date: Date | null,
        fieldName: DateType | string
    ) => {
        handleDate(date, fieldName, setValue, getValues, trigger);
    };

    const handleChange = (selected: Option) => {
        setValue('address', selected.value as number);
        trigger('address');
    };

    // This block of code is used only to rerender the form, to show select all selected of dropdown.
    useEffect(() => {
        setisDaySelected(!isDaySelected);
    }, [daysSelected]);

    return {
        register,
        handleSubmit,
        onSubmitForm,
        setValue,
        trigger,
        watch,
        errors,
        isValid,
        getValues,
        data,
        apiError: apiError as ErrorObj,
        isLoading: isSubmitting || isLoading || isUpdating,
        isDataFethcing,
        handleDateChange,
        show,
        setShow,
        setInitialValues,
        control,
        specialDataById,
        daysSelected,
        handleDaysChange,
        handleTimeChange,
        saveSlotHandler,
        timeSlot,
        isSlotDisabled,
        removeTimeSlot,
        label: getLabel(type, false),
        loadingLabel: getLabel(type, true),
        duplicateCSS: type === EventActionType.DUPLICATE,
        businessOptions,
        handleChange,
        getSelectedValue,
    };
}
