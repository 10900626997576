import { routeObj } from '../../../shared/constants/routes.constants';
import { useLocation, useNavigate } from 'react-router-dom';
import { isCognitoAuthAllowed } from '../../../shared/utils/helper';
import {
    ButtonLabel,
    ToastEnum,
} from '../../../shared/constants/html.constant';
import { useEffect, useMemo, useState } from 'react';
import { ConstNumber } from '../../../shared/constants/number.constant';
import {
    Params,
    localStorageKeys,
} from '../../../shared/constants/application.constant';
import {
    getTempLogin,
    setDataIntoLocalStorage,
} from '../../../shared/utils/storage.helpers';
import { useCompleteUserMutation } from '../slices/slice.authApi';
import { useAppDispatch } from '../../../../store/hooks';
import { signInUser } from '../slices/slice.auth';
import Toast from '../../../shared/utils/toast.helper';

export function useVerifySuccess(from: string) {
    const navigate = useNavigate();
    const { state } = useLocation();
    const [disable, setDisable] = useState(true);
    const [isPswrdInvld, setPswrdVld] = useState(false);
    const isAuthAllowed = isCognitoAuthAllowed() && from === Params.OTP_SCREEN;
    const localStorageData = useMemo(() => getTempLogin(), []);
    const [completeUser] = useCompleteUserMutation();
    const dispatch = useAppDispatch();

    const goToSubscription = () => {
        if (isPswrdInvld) {
            Toast.error(
                ToastEnum.SOMETHING_WENT_WRONG,
                ToastEnum.INVLD_PASSWORD
            );
        } else {
            navigate(routeObj.SIGN_UP_BUSINESS_TYPE);
        }
    };

    const backToSignIn = () => {
        navigate(routeObj.DEFAULT);
    };

    const clickHandler = () => {
        if (isAuthAllowed) {
            goToSubscription();
        } else {
            backToSignIn();
        }
    };

    const getLabel = () => {
        if (isAuthAllowed) {
            return ButtonLabel.SUBSCRIPTION;
        } else {
            return ButtonLabel.BACK_TO_SIGNIN;
        }
    };

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setDisable(false);
        }, ConstNumber.VALUE_4000);
        return () => {
            clearTimeout(timeoutId);
        };
    }, []);

    async function listenToAutoSignInEvent() {
        dispatch(signInUser(localStorageData))
            .unwrap()
            .then(async (res) => {
                setPswrdVld(false);
                setDataIntoLocalStorage(localStorageKeys.TEMP_LOGIN, '', true);
                //saved data into local storage
                setDataIntoLocalStorage(localStorageKeys.USER, {
                    role: '',
                    access_token: res.signInUserSession.accessToken.jwtToken,
                    username: res.username,
                    expirationTime:
                        res.signInUserSession.accessToken.payload.exp,
                });
                await completeUser({});
            })
            .catch((_error) => {
                setPswrdVld(true);
                Toast.error(ToastEnum.SOMETHING_WENT_WRONG, _error.message);
            });
    }

    return {
        state,
        goToSubscription,
        backToSignIn,
        clickHandler,
        getLabel,
        disable,
        localStorageData,
        listenToAutoSignInEvent,
    };
}
