import { useState } from 'react';
import { Form } from 'react-bootstrap';
import SingleSelect from './SingleSelect';
import { DateRangeElement } from './DateRangeElement';
import { EVENT_STATUS_OPTIONS } from '../constants/data.constant';

type Props = {
    searchHandler: (value: string) => void;
    showPublished?: boolean;
    dropDownHandler?: (newValue: any) => void;
    dateHandler: (dates: [Date | null, Date | null]) => void;
};

const Filter = (props: Props) => {
    const { searchHandler, showPublished, dropDownHandler, dateHandler } =
        props;
    const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
        null,
        null,
    ]);
    const [startDate, endDate] = dateRange;

    return (
        <>
            <Form.Control
                type="text"
                placeholder="Search by name or city"
                onChange={(e) => searchHandler(e.target.value)}
            />
            {showPublished && dropDownHandler && (
                <Form.Group className="form-group" controlId="categories_list">
                    <SingleSelect
                        options={EVENT_STATUS_OPTIONS}
                        isLabel={false}
                        isSearchable={false}
                        isClearable={false}
                        changeHandler={dropDownHandler}
                        placeholder="Event Status"
                    />
                </Form.Group>
            )}
            <DateRangeElement
                dateHandler={(dates) => {
                    setDateRange(dates);
                    if ((dates[0] && dates[1]) || (!dates[0] && !dates[1])) {
                        dateHandler(dates);
                    }
                }}
                endDate={endDate}
                startDate={startDate}
            />
        </>
    );
};

export default Filter;
