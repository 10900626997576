import { interceptorMiddleware } from '../../../shared/services/interceptor.service';
import { createApi } from '@reduxjs/toolkit/query/react';
import { apiUrlBusiness } from '../constants/apiUrl.businesses';
import { HttpMethods } from '../../../shared/constants/application.constant';
import { SearchedBusiness } from '../utils/types.businesses';
import { apiUrlBusinessInfo } from '../../../modules/businessInfo/utils/apiUrl.businessInfo';
import {
    BusinessUserDetais,
    BusinessUserDetaisResponse,
    VenueDetails,
    VenueDetailsResponse,
} from '../../../modules/businessInfo/utils/types.businessInfo';
import { apiUrlEvents } from '../../../modules/events/constants/apiUrl.events';
import { ConstNumber } from '../../../shared/constants/number.constant';
import { ListResponse } from '../../../shared/utils/types.elements';
import { Event, EventV2 } from '../../../modules/events/constants/types.events';
import { apiUrlSpecials } from '../../../modules/specials/constants/apiUrl.specials';
import { LocalSpecials } from '../../../modules/specials/utils/types.specials';

export const businessSearchApi = createApi({
    reducerPath: 'businesses_search',
    baseQuery: interceptorMiddleware,
    tagTypes: ['businesses_search'],
    endpoints: (builder) => ({
        getBusinessBySearch: builder.mutation<SearchedBusiness[], string>({
            query: (search: string) => ({
                url: `${apiUrlBusiness.GET_BUSINESSES}?limit=5&search=${search}`,
                method: HttpMethods.GET,
            }),
            transformResponse: (data: any) => data.data,
        }),
        getBusinessInfoById: builder.query<BusinessUserDetais, number>({
            query: (id: number) => ({
                url: `${apiUrlBusinessInfo.BUSINESS_PROFILE}?user_id=${id}`,
                method: HttpMethods.GET,
            }),
            transformResponse: (res: BusinessUserDetaisResponse) => res.data,
        }),
        getBusinessLevelTwo: builder.query<VenueDetails[], number>({
            query: (id: number) => ({
                url: `${apiUrlBusinessInfo.GET_BUSINESS_LEVEL_2}/${id}/`,
                method: HttpMethods.GET,
            }),
            transformResponse: (res: VenueDetailsResponse) => res.data,
        }),
        getEventsById: builder.query<
            ListResponse<Event>,
            { page: number; id: number; isClaimed?: string }
        >({
            query: ({ id, page, isClaimed }) => ({
                url: `${apiUrlEvents.EVENTS}?user_id=${id}&limit=12&offset=${
                    (page - ConstNumber.VALUE_1) * ConstNumber.VALUE_12
                }&is_claimed=${isClaimed}`,
                method: HttpMethods.GET,
            }),
            keepUnusedDataFor: ConstNumber.VALUE_1,
        }),
        getEventDetail: builder.query<
            EventV2,
            { eventId: number; userId: number }
        >({
            query: ({ eventId, userId }) => ({
                url: `${apiUrlEvents.EVENTS}${eventId}/?user_id=${userId}`,
                method: HttpMethods.GET,
            }),
            keepUnusedDataFor: ConstNumber.VALUE_1,
        }),
        getEventLevel2Detail: builder.query<
            VenueDetails[],
            { eventId: number }
        >({
            query: ({ eventId }) => ({
                url: `${apiUrlBusiness.EVENT_LEVEL2_DETAILS}/${eventId}/`,
                method: HttpMethods.GET,
            }),
            transformResponse: (res: VenueDetailsResponse) => res.data,
        }),
        deleteEventById: builder.mutation<
            { message: string },
            { id: number; userId: number }
        >({
            query: ({ id, userId }) => {
                return {
                    url: `${apiUrlEvents.EVENTS}${id}/?user_id=${userId}`,
                    method: HttpMethods.DELETE,
                };
            },
        }),
        getSpecialsById: builder.query<
            ListResponse<LocalSpecials>,
            { page: number; id: number; offerType: number }
        >({
            query: ({ id, page, offerType }) => ({
                url: `${
                    apiUrlSpecials.SPECIALS
                }?user_id=${id}&offer_type=${offerType}&limit=12&offset=${
                    (page - ConstNumber.VALUE_1) * ConstNumber.VALUE_12
                }`,
                method: HttpMethods.GET,
            }),
            keepUnusedDataFor: ConstNumber.VALUE_1,
        }),
        getSpecialDetail: builder.query<
            LocalSpecials,
            { id: number; userId: number }
        >({
            query: ({ id, userId }) => ({
                url: `${apiUrlSpecials.SPECIALS}${id}/?user_id=${userId}`,
                method: HttpMethods.GET,
            }),
            keepUnusedDataFor: ConstNumber.VALUE_1,
        }),
        deactivateBusiness: builder.mutation<
            { message: string },
            { is_active: boolean; email: string }
        >({
            query: ({ is_active, email }) => {
                const request = {
                    is_active,
                    email,
                };

                return {
                    url: apiUrlBusiness.USER_STATUS,
                    method: HttpMethods.POST,
                    body: request,
                };
            },
        }),
        deleteSpecialById: builder.mutation<
            { message: string },
            { id: number; userId: number }
        >({
            query: ({ id, userId }) => {
                return {
                    url: `${apiUrlSpecials.SPECIALS}${id}/?user_id=${userId}`,
                    method: HttpMethods.DELETE,
                };
            },
        }),
    }),
});

export const {
    useGetBusinessBySearchMutation,
    useGetBusinessInfoByIdQuery,
    useGetBusinessLevelTwoQuery,
    useGetEventsByIdQuery,
    useGetEventDetailQuery,
    useGetEventLevel2DetailQuery,
    useDeleteEventByIdMutation,
    useGetSpecialsByIdQuery,
    useGetSpecialDetailQuery,
    useDeactivateBusinessMutation,
    useDeleteSpecialByIdMutation,
} = businessSearchApi;
